.popup-field {
    margin: 1px 5px;
    padding: 5px;
    display: flex;

}

.popup-field-label{
    padding: 5px;
    flex: 0 0 120px;
    color: var(--fg-color-3);
    font-weight: 400;
}


.popup-field > input,
.popup-field > textarea
 {
    padding: 8px 10px;
    min-width: 100px;
    flex: 1 1;
    margin-bottom: 5px;
    border: 0.5px solid #E5E7EB;
    border-radius: 6px;
    background: #f9fafb;


    color: var(--hg-color-1); 
    font-weight: 300;
    
    font-size: 12px;

}




.create-app-modal-body .text-md{
    font-size:12px;
}

.create-app-modal-body input::placeholder, .create-app-modal-body textarea::placeholder{
    font-size:12px;
    font-weight: lighter;
}
.create-app-modal-body textarea::-webkit-resizer{
    width: 9px;
    height: 9px;
    color: #94A3B8;

}


.create-app-modal-body{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px;
}

.id-directory{
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #64748B;
}

.name-directory{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

















  
.empty-result-container{
    display: flex;
    padding: 60px 60px 80px 60px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    justify-content: center;
}

.empty-result-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.empty-result-message-top{
    color: #1E293B;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}

.empty-result-message-bottom{
    color: #64748B;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
}

.list-actions-deliver .list-options-item.light:first-child{
    color: #B91C1C;
}




.studio-container{
    position: relative;
    top: 0; 
    flex: 1;
}
.studio-container .Resizer.horizontal{
    display: block;
    cursor: row-resize;
    height: 4px;
    background-color: #C6C6C6;
    box-shadow: 0 0 1px 1px #C6C6C6;
    opacity: 0;
    transition: 0.3s all;
} 
.studio-container .Resizer.horizontal:hover{
    opacity: 1;
}


.studio-container .SplitPane.horizontal{
    /* gap: 0; */
    display: block!important;
} 

.studio-container .Pane.horizontal.Pane1 {
    max-height: calc( 100% - 5px );
}

.studio-container .Pane.horizontal.Pane2 {
    display: flex;
    height: 100%;
}