.setting-deletion-container{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.settings-deletion-authContainer{
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.settings-deletion-auth{
    display: flex;
    gap: 16px;
}

.settings-deletion-btn{
    display: flex;
    justify-content: flex-end;
}

.settings-confirmPass-deletion-auth, .settings-Password-deletion-auth{
    width: 32%;
}

.settings-deletion-eyeIcon{
    bottom: 32px;
}

#settings-delete-undisable{
    background: #DC2626;
    color: white;
}

#settings-delete-undisable span{
    color: white !important;
}

.settings-del-err-mes-btn .ui-err-mes-container{
    justify-content: center;
}

@media screen and (max-width:1000px) {
    .settings-deletion-auth{
        flex-direction: column;
    }
    .settings-deletion-passwordContent,.settings-deletion-confirmPasswordContent,.settings-deletion-usernameContent{
        width: 100% !important;
    }
}