.validation-panel {
    width: 100%;
    height: 100%;
    background: #eee8;
    border-top: #aaa 1px solid;
  }
  
  .validation-panel  .tab-container {
    display: flex;
  }
  
  .validation-panel  .tab {
    cursor: pointer;
    padding: 5px 20px;
    border-bottom: 2px solid transparent;
    font-size: 14px;
  }
  
  .validation-panel  .tab.active {
    border-bottom: 2px solid #333;
  }
  
  .validation-panel  .content-container {
    display: flex;
    height: calc(100% - 45px);
  }
  
  .validation-panel  .master-detail {
    display: flex;
    flex: 1;

    background: #FFF;
    border-top: 1px solid #ddd;
  }
  
  .validation-panel  .master {
    flex: 0 0 35%;
    border-right: 1px solid #ccc;
    padding: 10px 20px;
    overflow: auto;

  }
  
  .validation-panel   .vmm-error-list {
    list-style: none;
    padding: 0 8px;
  }

  .validation-panel  .vm-file-list    .vmm-error-list { 
    padding: 0 16px;
    margin-top: 4px;
  }
  
  .validation-panel  .vmm-error-list li {
    margin-bottom: 10px;
  }
  
  .validation-panel   .vmm-error-title {
    font-weight: bold;
    font-size: 12px;
  }
  .validation-panel  .vmm-error-title i {
   margin-right: 8px;
   font-size: 13px;
  }
  .vmm-error-item {
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .validation-panel  .detail {
    flex: 1 1;
    padding: 10px 20px;
    overflow: auto;
    height: auto;
  }
  .validation-panel  .detail > div { 
  }
  .validation-panel  .action-buttons { 
      
    padding: 15px 10px;
    border-right: 1px solid #dddddd;
    background: #f6f6f6;
  }
  
  .validation-panel  .action-buttons .vp-btn {
    margin-bottom: 10px;
    line-height: 24px;
  }
  .validation-panel  .vp-btn {
      width: 24px;
      height: 24px;
      text-align: center;
      vertical-align: 24px;
      color: #aaa;
      border-radius: 5px;
  }
  .validation-panel .vp-btn.active { 
    cursor: pointer;
    color: #888;
}
.validation-panel  .vp-btn.active:hover { 
    cursor: pointer;
    background-color: #ddd;
}
.validation-panel .vp-btn-run.active {
    color: green;
    cursor: pointer;
}
.validation-panel .vp-btn-stop.active {
    color: red;
    cursor: pointer;
}
.validation-panel .vmd-title {
    font-size: 20px;
    font-weight: 700;
    margin-top: 4px;
    margin-bottom: 4px;
}

.validation-panel .vmd-hb{
    display: flex;
    gap: 2rem;
    margin-top: 1px;
    margin-bottom: 6px;

}

.validation-panel .vmd-hb-block {


}

.validation-panel .vmd-hb-block span {
    margin-left: 8px;
    text-transform: capitalize;
    font-size: 16px;
}

.validation-panel .vmd-hb-block i  {

}

.validation-panel .vmd-message {
    margin-top: 1rem;
    margin-bottom: 1rem;

}
.validation-panel .vmd-description {
    margin-top: 1rem;
    margin-bottom: 1rem;

}
.validation-panel .vmd-non-compliant {
    margin-top: 1rem;
    margin-bottom: 1rem;

}
.validation-panel .vmd-compliant {
    margin-top: 1rem;
    margin-bottom: 1rem;

}

.validation-panel  .vm-rubric-name {
    cursor: pointer;

}
.validation-panel .vm-rubric-name i {
    margin-right: 4px;
    width: 14px;
}

.validation-panel .vmm-error-link {
    padding-left: 40px;
}
.validation-panel .vmm-error-link span:hover {
    text-decoration: underline;
}
.validation-panel .lpath-tkn {
  color: #666;
  padding-right: 4px;
  
}

.validation-panel  .lpath-tkn i {
  color: #bbb; 
  
}