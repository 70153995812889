.container-head {
    height: 66px;
    border-bottom: 1px solid #E2E8F0;
    gap: 8px;
    display: flex;
    justify-content: space-between;
}

.container-content{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.container-subtitle {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    color: #64748B;
    margin: 0px;
}

.container-title{
    font-family: 'Inter';
    font-weight: 500;
    margin: 0px;
    font-size: 15px;
}
.settings-action-btn{
    background-color: transparent;
    border: none;
    color: #2563EB;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
}
.conatiner-title-section{
    gap: 6px;
    display: flex;
    flex-direction: column;
}