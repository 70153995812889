.workspace-container{
    display: flex;
    gap: 32px;
    flex-direction: column;
}

.login-logo-workspace{
    margin-bottom: 0px !important;
    width: 80px;
    height: 80px;
    object-fit: contain;
}
.Uploaded-logo-workspace{
    margin-bottom: 0px !important;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50px !important;
}
.error-message{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#FEF2F2 ;
    border: #FCA5A5 1px solid;
    box-shadow: 0px 1px 2px 0px #0000000D;
    gap: 6px;
    padding: 8px 12px;
    border-radius: 4px;
}
.workspace-settings-contant{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.workspace-imgSec-main{
    display: flex;
    gap: 32px;
    flex-direction: column;
}
.error-logo-workspace{
    color: #DC2626;
}

.workspace-error-message{
    font-family: "Inter";
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    color: #1E293B;
}
.workspace-imgSec{
    display: flex;
    align-items: center;
}

.workspace-logo{
    align-items: center;
    text-align: center;
    width: 80px;
    padding: 10px;
    border-radius: 100px;
    border: 1px solid #E2E8F0;
    height: 80px;
    display: flex;
    justify-content: center;
}

.Imgrestriction-workspace{
    margin-left: 2rem;
}

.Img-title{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
    color: #334155;
    margin-top: 8px;
}

.Img-title span{
    color: #2563EB;
}

.workspace-edit-icon{
    display: none;
}

.workspace-logo:hover .login-logo-workspace {
    display: none;
  }
  
.workspace-logo:hover .Uploaded-logo-workspace {
    display: none;
  }
  
.workspace-logo:hover .workspace-edit-icon {
    display: block; 
    color: #475569;
    text-align: center;
    background-color: #E2E8F0;
    margin-bottom: 0px !important;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: #94A3B8 solid 1px;
    box-shadow: inset 0px 0px 14px 0px #94A3B8;
    cursor: pointer;    
}

.new-edit-logo-workspace{
    display: flex;
    width: 100%;
    height: 100%;
    color: #475569;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    padding: 28px;
}

.file-upload {
    display: none; 
}

.workspace-personal-detials{
    display: flex;
    gap: 16px;
}
.workspace-personal-sub-detials{
    display: flex;
    gap: 16px;
}

.workspace-Img-mobile{
    display: none !important;
}


.workspace-submit-button{
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width:600px) {
    .workspace-personal-detials{
        display: flex;
        flex-direction: column !important;
    }
    .workspace-personal-sub-detials{
        flex-direction: column !important
    }
    .settings-content{
        padding: 24px 10px !important
    }
    .workspace-Img-desktop{
        display: none;
    }
    .workspace-Img-mobile{
        display: block !important;
    }
}
