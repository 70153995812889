.page-header-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-header-sec h3 {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E293B;
    margin: 0px;
}

.pagetitle-h3 {
    margin: 10px 0px;
}

.searchSec {
    display: flex;
    margin-top: 2rem;
}

.options {
    height: 19px;
    display: flex;
    align-items: center;
}

.organizationoptions {
    position: absolute;
    border: 1px solid #cdd4e0;
    padding: 4px 9px 4px 8px;
    border-radius: 10px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    z-index: 9999;
    opacity: 1;
    background: white;
}

.myorganization {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
}

.optiontext {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E293B;
}

.options img.login-logo {
    object-fit: contain;
    padding-right: 0.5rem;
    height: 14px;
    width: 17px;
}

.organizationCount {
    padding: 3px 8px 3px 8px;
    color: #2563EB;
    background: #EFF6FF;
    border: 1px solid #BFDBFE;
    border-radius: 4px;
    margin-left: 10px;
}

.iconsSection {
    display: flex;
    position: relative;
    right: 5%;
}

.searchfilter {
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

.searchText {
    width: 78%;
}

img.clearSearch {
    padding-right: 12px;
}

.pageView {
    display: flex;
}

img.filter_list {
    padding-right: 10px;
    margin-right: 10px;
    position: relative;
    right: 48%;
}

.searchSec.filterText .input-container {
    width: 45%;
}

.pageView div {
    width: 40px;
    height: 40px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 12px;
    border-radius: 8px;
}

.pageView .active {
    background: #F8FAFC;
}

.filtersection {
    margin-left: -30px;
    width: 10%;
    cursor: pointer;
}

.grid_view {
    margin-right: 12px;
    margin-left: 21px;
}

img.verticalline {
    margin-left: -16px;
    margin-right: -9px;
    padding: 0 21px 0px 3px;
}

.filter_list {
    right: 2%;
}

@media (min-width:800px) {
    .organizationsAll.row {
        display: flex;
        flex-wrap: wrap;
        margin-top: 3rem;
    }
    /* .new-app-list{
        left: -108px !important
    } */

}

@media (max-width: 800px) {

    .organizationsAll.row {
        display: flex;
        margin-top: 3rem;
        overflow-x: auto;
        scrollbar-width: none;
    }

    .organizationsAll .cardContainer {
        margin-right: 15px;
    }
    .new-app-list{
        left: -159px !important
    }
}

.organizationsAll img.img {
    width: 280px;
    height: 140px;
    object-fit: contain;
}

.organziationImg {
    width: 280px;
    height: 145px;
    border: 1px solid #CBD5E1;
    border-radius: 12px;
}

p.organization-name {
    display: flex;
    margin-top: 0.6rem;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E293B;
    margin-bottom: 0px;
    width: 280px;
}

.organization-date {
    display: flex;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0px;
    color: #64748B;
}

.col-md-3.organzationList {
    margin-bottom: 1rem;
}

img.badge {
    margin-left: 6px;
}

img.dot {
    margin-right: 6px;
}

.modal-organizaiton {
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    scrollbar-width: none;
}

/* Modal Content */
.modal-organizaiton-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

/* The Close Button */
.close-organizaiton {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-organizaiton:hover,
.close-organizaiton:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.my-app-filtersection div{
    border: none !important;
}

.my-app-filtersection .input-container{
    left: 28px;
}

.modal-organizaiton .kls-card-signin {
    padding-top: 20px !important;
}

.col-md-3.organzationList {
    margin-bottom: 1rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

img.viewmore {
    position: relative;
    left: 90%;
    top: 12%;
}

.viewmoreAction {
    position: relative;
    width: 100px;
    height: 104px;
    border-radius: 4px;
    border: 1px solid #CBD5E1;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    bottom: 82%;
    background: white;
    left: 90%;
}

@media screen and (max-width:1025px) and (min-width:800px) {
    .iconsSection {
        display: flex;
        position: relative;
        right: 8%;
    }

    .filter_list {
        right: 3%;
    }

    .filtersection {
        margin-left: -29px;
        width: 16%;
    }
}

@media screen and (min-width:800px) {
    .organizationSec {
        max-width: 1250px;;
        margin: 6rem auto;
    }

}

@media screen and (max-width:800px) {
    .organizationSec {
        margin: 2.5rem auto;
    }

    .iconsSection {
        display: flex;
        position: relative;
        right: 11%;
    }

    .filtersection {
        margin-left: -30px;
        width: 30%;
    }

    .filter_list {
        right: 5%;
    }

}

.workchoice.buttons.popup {
    justify-content: flex-end;
}


img.badge {
    height: 15px;
    width: 15px;
}

.new-app-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.new-app-list {
    position: absolute;
}

.industrySec {
    align-items: center;
    background: #F1F5F9;
    border: 1px solid #f1f5f9;
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 #0000000d;
    display: flex;
    height: 25px;
    justify-content: center;
    position: absolute;
    width: 25px;
    top: 70%;
    left: 85%;
}

.industrySec img.industry {
    width: 15px;
    height: 12px;
}

.listview {
    margin-top: 3rem;
    padding-bottom: 3rem;
}

.listview-no-result {
    margin-top: 3rem;
}

.organizationImg {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-top: 2px;
    margin-bottom: 2px;
}

.domain-org{
    left: calc(100% - 76px) !important;
}

.passwordSec{
    display: grid;
}

.eyeIcon{
    left: calc(100% - 30px) !important;
}

.Mobile {
    display: none;
}

.display-mode-myOrg{
    width: 40px;
    height: 40px;
    padding: 12px;
    border-radius: 8px;
    gap: 12px;
    background: #FFF;
    border: none;
    color: #64748B;
}

.display-mode-myOrg:hover{
    background: #F8FAFC;
    color: #475569;
}

.display-mode-myOrg.selected{
    color: #2563EB;
    background: #F8FAFC;
}

@media screen and (max-width:800px) {
    .Laptop {
        display: none;
    }

    .Mobile {
        display: block;
    }
}

@media screen and (max-width:600px) {
    .mobile-sec-child-icon {
        right: 16% !important;
    }
}

@media screen and (max-width:500px) {
    .Laptop {
        display: none;
    }

    .Mobile {
        display: block;
    }

    .filtersection {
        display: none !important;
    }

    .filter_list {
        padding: 10.5px;
        border: 1px solid #E2E8F0;
        border-radius: 8px;
    }

    .filter_list .clearSearch {
        padding-right: 0px !important;
    }

    .mobile-sec-child-icon {
        padding-right: 10px !important;
    }

    .mobile-sec-child-icon {
        right: 20% !important;
    }

}

#action-mobile{
    width: 40px;
    height: 40px;
    padding: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border-radius: 8px;
    background-color: var;
}

@media screen and (min-width: 1440px){
    #action-mobile{
        display: none;
    }
    
}

@media screen and (max-width: 1440px){
    #action-mobile{
        display: none;
    }
    
}

@media screen and (max-width: 1025px){
    .organizationSec{
        padding: 0 20px;
    }
    #action-mobile{
        display: none;
    }
    
}

@media screen and (max-width: 800px){
    #action-dekstop{
        display: none;
    }
    #action-mobile{
        display: block;
    }
}


@media screen and (min-width: 500px) {
    .Img_lap {
        display: block;
    }
    .Img_mobile {
        display: none;
    }
}
@media screen and (max-width: 499px) {
    .Img_lap {
        display: none;
    }
    .Img_mobile {
        display: block;
    }
    .organizationSec{
        padding: 0 20px;
    }
}
/* 
.Organisation-default-GenericCard .industry{
    left: -32px;
    position: relative;
} */