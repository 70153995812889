.access-security-container{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.app-security-userDetais{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.app-security-username-email{
    display: flex;
    gap: 16px;
}

.app-security-content{
    width: 34%;
}

.app-security-changePasswordContainer{
    display: flex;
    justify-content: space-between;
}

.app-security-changePasswordContent{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.app-security-changePasswordTitle{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    margin: 0;
}

.app-security-changePasswordSubtitle{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    margin: 0;
}

.app-security-phone-socialMedia{
    display: flex;
    gap: 16px;
}

.app-security-telephone{
    width: 33%;
}

.app-security-phoneTitle, .app-security-socialTitle{
    margin: 0;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
}
.app-security-telephone div .font-400 .red-600{
    padding: 0 !important;
}

.app-security-socialMedia{
    width: 67%;
}

.app-security-DoubleAuthContainer{
    display: flex;
    gap: 8px;
}

.app-security-authContent{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.app-security-authTitle{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    margin: 0;
}

.app-security-authSubtitle{
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    margin: 0;
}

.app-security-DoubleAuthContainer .container-toggler{
    align-items: unset;
}

.app-security-btn{
    display: flex;
    justify-content: flex-end;
}

.app-security-telephone .telephone-container .input-container div{
    padding: 11.5px 12px !important;
}

.app-security-telephone .input-container div.font-400.text-md.red-600 {
    padding: 0 !important;
}

.app-security-authContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 16px;
}

.app-security-btn .organistaion-delete-button-address-model{
    background-color: #3B82F6 !important;
    border: 1px solid #2563EB ;
}

.security-eyeIcon{
    bottom: 32px;
}

.users-popup-button{
    background-color: #ffff;
    box-shadow: 0px 2px 3px 0px #0000000D;
    border: 1px solid #E2E8F0;
    padding: 10.5px 12.5px;
    border-radius: 8px;
}

.changePassword-mobile {
    display: none;
}

.security-verification-mobile {
    display: none;
}

@media screen and (max-width: 650px) {
    .app-security-username-email{
        flex-direction: column;
    }
    .app-security-content{
        width: unset;
    }
    .app-security-phone-socialMedia{
        flex-direction: column;
    }
    .app-security-telephone{
        width: unset;
    }
    .app-security-socialMedia{
        width: unset;
    }
    .app-security-changePasswordContainer{
        justify-content: unset;
        flex-direction: column;
        gap: 20px;
    }
}

@media screen and (max-width: 600px) {
    .changePassword-content {
        display: none;
    }
    .changePassword-mobile {
        display: block;
    }
    .security-verification-mobile {
        display: block;
    }
    .security-verification-content {
        display: none;
    }
}