.course-tab-container {
    width: 25%;
}

@media screen and (max-width:800px) {
    .mobile-version{
        display: block !important;
    }
    .Desktop-version{
        display: none !important;
    }
    .mobile-version-content{
        width: auto !important;
        border: none !important;
    }    

.mobile-version .side-navbar{
    width: 100%;
}

.mobile-version .tabs-kls-vertical{
    width: -webkit-fill-available;
    border: 0;
    display: flex;
    flex-direction: column;
    gap: 21px;
    padding-top: 16px;
}

.mobile-version .tabs-kls-vertical .tab.selected  {
    border-bottom: 2px solid #2563EB !important;
    border-left: none;
    margin-left: 0;
}

.mobile-version .tabs-kls-vertical .medium{
    border-bottom: #ADB5BD 1px solid ;
    gap: 12px;
}


.settings-sidebar-mobile-header{
    border-bottom: 0.5px solid #D1D5DB;
    height: 60px;
    padding: 20px 30px;
    background: #F9FAFB;
    display: flex;
    gap: 24px;
    align-items: center;
}

.arrow-back-settings{
    color: #000000;
    font-size: 16px;
}

.mobile-version-content .arrow-back-settings{
    color: #000000;
    font-size: 24px;
    position: relative;
    top:42px;
    z-index: 10;
}

.settings-sidebar-mobile-header h1{
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 600;
    width: auto;
    margin: 0;
}

.settings-sidebar-mobile-header a{
    display: flex;
    align-items: center;
}
.mobile-version-content .container-content{
    position: relative;
    padding-left: 42px;
}


}



@media screen and (min-width:801px) {
    .mobile-version{
        display: none !important;
    }
}