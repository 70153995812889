.app-payment-container{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.app-payment-headerContent{
    display: flex;
    flex-direction: column;
    height: 66px;
    border-bottom: 1px solid #E2E8F0;
    gap: 8px;
}

.app-payment-title{
    margin: 0;
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 500;
}

.app-payment-subtitle{
    margin: 0;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    color: #64748B;
}

.app-payment-container thead tr {
    background-color: transparent !important;
}
.app-payment-container thead th {
    border-bottom: none !important;
}
.app-payment-container td {
    border-bottom: none !important;
}
.app-payment-container tbody td{
    border-top: 1px solid #E2E8F0 !important;
}
.app-payment-container table {
    box-shadow: none !important;
    border: none !important;
}
.app-payment-container tbody tr{
    height: 60px !important;
    border-top: 1px solid #E2E8F0 !important;
}

/* Media Query For mobile Screen */
@media screen and (max-width: 633px){
    .app-payment-dataContainer{
        overflow: scroll;
    }
    .app-payment-dataContainer::-webkit-scrollbar{
        display: none;
    }
}