@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  src: url(../public/fonts/icons/material-icons-outlined.woff2) format('woff2');
}
/* static fonts */
@font-face { font-family:Inter; font-style:normal; font-weight:500; font-display:swap; src:url("../public/fonts/inter/Inter-Medium.woff2") format("woff2");   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }


#root,.App{
height: 100%;
}
.App{
display: flex;
flex-direction: column;
}
.service-editor{
flex-grow: 1;
}

.form-new-element{
  width: auto !important;
  margin: 10px auto!important;
  max-width: 500px;
}

.inform-save{
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 5px;
  top: 5px;
  box-shadow: 0 0 10px 10px rgba(52, 52, 75, 0.3);
  color: var(--fg-color-1);
  gap: 20px;
  padding: 8px 36px;
  z-index: 1000;
}

.inform-save .icon{
  border: 1px solid var(--fg-color-1);
  border-radius: 50%;
  padding: 4px;
}


.material-icon, .icon {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 16px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}
.w-full{
  width: 100%;
}

.toasts-container{
  z-index: 10000000;
  position: fixed;
  padding: 0rem;
  max-width:760px;
  box-sizing: border-box;
  color: #fff;
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width:1100px) {
.navbar-container{
  padding-left: 3% !important;
}
}

.navbar-container{
padding-left: 8%;
}

.navbar-container{
  flex-grow: 1;
  display: flex;
  justify-content: center;
  gap: 24px;
}

.color-link:hover {
font-weight: 400!important;
}

.color-link {
  color: #FFFFFF;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
}


.link-container.selected,.link-container:hover{
background: #1E293B;
cursor: pointer;
}

.link-container{
padding: 6.5px 16px 6.5px 16px ;
border-radius: 100px;
background: #020617;
text-align: center;
}


.material-icon-modules, .icon-modules {
font-family: 'Material Symbols Outlined';
font-weight: normal;
font-style: normal;
font-size: 20px;  /* Preferred icon size */
display: inline-block;
text-transform: none;
letter-spacing: normal;
word-wrap: normal;
white-space: nowrap;
direction: ltr;
color:rgb(0, 0, 0);

}
.appcomponent-label{
font-family: Inter;
font-size: 15px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: left;

}



.ellipsis{
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
}

.icon-span-placeholder{
font-family: Inter;
font-size: 13px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
color: #6B7280;
text-align: left;

}


textarea::placeholder, input::placeholder{
font-family: Inter;
font-size: 12px;
font-weight: 400;
letter-spacing: 0em;
text-align: left;
color: var(--slate-400);
}
.app-configuration-container{
padding: 20px;
display: flex;
flex-direction: column;
gap: 20px;
}
.logo-section-appconfig{
flex: 2;
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;
}
.piltab-appComponent{
flex: 1;
}
.piltab-appComponent .screeen-size-selector .piltabs-container{
width: 100%;
}
.piltab-appComponent .screeen-size-selector .screen-icon {
gap: 4px;
  width: 100%;
}
.piltab-appComponent .screeen-size-selector .screen-icon :first-child{
color:#10B981
}
.piltab-appComponent .screeen-size-selector .screen-icon :last-child{
  color:#1E293B
}

.dropdown-icon-list-appconfig{
display: flex;
justify-content: space-between;
border: 1px solid #E2E8F0;
border-radius: 5px;
align-items: center;
height: 41px;
cursor: pointer;
}
.name-directory-config{
flex: 0.33;
}
.Enregistrer-btn-appConfig{
display: flex;
justify-content: flex-end;
}



/* .shema-viewer-panel .xml-element-context-menu .xml-element-context-menu-item :last-child{
color: red;
} */

.header {
display: flex;
justify-content: end;
padding: 20px;
gap: 20px;
}

.signup,
.login {
padding: 10px 20px;
border: 1px solid #ccc;
background-color: #6666c4;
color: #fff;
text-decoration: none;
border-radius: 10px;
}


.navbar-container {
flex-grow: 1;
display: flex;
justify-content: center;
gap: 24px;
/* gap: 1vw; */
}

@media screen and (max-width: 800px) {
.navbar-container {
  display: none;
}
}

.color-link:hover {
font-weight: 400 !important;
}

.color-link {
color: #FFFFFF;
font-family: Inter;
font-size: 13px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
}


.link-container.selected,
.link-container:hover {
background: #1E293B;
cursor: pointer;
}

.link-container {
padding: 6.5px 16px 6.5px 16px;
/* padding: 6.5px 10px; */
border-radius: 100px;
background: #020617;
text-align: center;
}

@media screen and (max-width: 800px) {
.link-container {
  border-radius: 0;
  background: none;
}
}


.stepbar {
width: 68px;
height: 32px;
top: 9.98px;
left: 1110px;
padding: 3px 10px 3px 10px;
border-radius: 7px;
gap: 9px;
background: #16A34A;
color: #FFFFFF;
/* margin-left: 10%; */
display: flex;
justify-content: space-around;
align-items: center;
}

.steps-bar {
margin-right: 5%;
position: relative;
}

.progress-conatiner {
position: absolute;
}

.stepbar img {
width: 16.88px;
height: 16.88px;

}

.steps-count {
width: 21px;
height: 20px;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

}

.stepbar:hover {
cursor: pointer;
}

.closebtn:hover {
cursor: pointer;
}

@media screen and (max-width:1023px) {
.step-cards-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
}

.navbar-container {
  display: none;
}

.steps-bar {
  display: none;
}

.steps-bar-mobile {
  display: block;
}

.stepbar-header {
  font-family: 'Inter';
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #2563EB;
  align-items: center;
  display: flex;
  justify-content: flex-end;

}

.header-elements {
  justify-content: space-between;

}
}

@media screen and (min-width:1024px) {

.step-cards-container {
  padding: 20px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.steps-bar-mobile {
  display: none;
}
}

.steps-bar-mobile.close {
border-bottom: 0.5px solid #D1D5DB;
height: 57px;
padding: 20px 30px 20px 30px;
background: #F9FAFB;
}

.steps-bar-mobile.open {
padding: 20px 30px 20px 30px;
background: #F9FAFB;
border-bottom: 0.5px solid #D1D5DB;
}

.stepbar-section {
display: flex;
flex-wrap: wrap;
gap: 5px;
flex-direction: row;
}

.steps-bar-mobile .steps-count {
background: #ecfdf599;
border: 1px solid #a7f3d0;
border-radius: var(--4);
font-family: Inter;
font-size: 13px;
font-weight: 600;
height: 24px;
letter-spacing: 0;
line-height: 16px;
padding: 0 8px;
text-align: left;
display: flex;
color: #059669;
align-items: center;
width: 110px;
border: 1px solid #A7F3D0;
border-radius: 4px;
}

.stepbar-subhead {
display: flex;
gap: 11px;
margin-top: 2rem;
margin-bottom: 2rem;
}

.stepbar-subhead .guide {
font-family: 'Inter';
font-size: 15px;
font-weight: 500;
line-height: 23px;
letter-spacing: 0em;
text-align: left;
color: #1F2937;

}

.notifications {
width: 32px;
height: 32px;
display: flex;
align-items: center;
justify-content: center;
}

.notifications-icon {
color: white;
width: 20px;
height: 20px;

}

@media screen and (min-width: 1023px) and (max-width: 1051px) {
.steps-bar {
  margin-right: 0;
}
}

.organisation-settings-module{
padding: calc(100% - 91.5%);
padding-top: 10px;
padding-bottom: 10px;
width: 100%;
}

.organisation-settings-module .settings-header{
width: 100% !important;
margin: 0 !important;
}


.profile-notifications {
display: flex;
align-items: center;
gap: 24px;
}

.common-hint-main {
position: fixed;
background-color: rgba(96, 96, 96, 0.70);
top: 62px;
left: 0;
height: 93vh;
width: 100vw;
z-index: 460;
}

.orgData-hint-main {
z-index: 449;
}

.application-hint-main {
z-index: 499;
top: 0;
height: 100vh;
}

.theme-hint {
position: absolute;
top: 45px;
width: fit-content;
right: 230px;
z-index: 600;
}

.themer {
overflow: hidden;
}

.orgData-hint {
position: absolute;
top: 258px;
width: fit-content;
left: 38%;
z-index: 700;
}

.application-hint {
position: absolute;
top: 188px;
width: fit-content;
left: 37%;
z-index: 700;
}

.user-setting-hint {
position: absolute;
top: 0px;
width: fit-content;
right: 215px;
z-index: 600;
}

.theme-setting-view, .orgData-setting-view, .theme-para {
display: none;
}

.hint-stepbar-theme {
overflow: hidden;
}

@media screen and (min-width: 1300px) {
.orgData-hint {
  top: 210px;
  left: 38%;
}
}

@media screen and (max-width: 1299px) {
.orgData-hint {
  top: 220px;
}
.application-hint {
  left: 39%;
  top: 135px;
}
}

@media screen and (max-width: 1150px) {
.orgData-hint {
  top: 195px;
  left: 40%;
}
}

@media screen and (min-width: 1100px) {
.user-setting-hint {
  top: 22px;
  right: 262px;
}
}

@media screen and (max-width: 1100px) {
.orgData-hint {
  top: 170px;
  left: 46%;
}

.application-hint {
  left: 48%;
  top: 175px;
}

.theme-hint {
  top: 30px;
}
}

@media screen and (max-width: 1023px) {
.orgData-hint {
  top: 225px;
  left: 47%;
}
}

@media screen and (max-width: 900px) {
.orgData-hint {
  top: 230px;
  left: 51%;
}

.application-hint {
  left: 53%;
  top: 140px;
}

.theme-hint {
  top: 45px;
}

}

@media screen and (max-width: 960px) {
.application-hint {
  top: 165px;
  left: 50%;
}
}

@media screen and (max-width: 830px) {
.orgData-hint {
  top: 230px;
  left: 56%;
}

.application-hint {
  left: 55%;
}
}

@media screen and (max-width: 800px) {
.orgData-hint {
  top: 195px;
  left: 64%;
}

.application-hint {
  left: 62%;
  top: 100px;
}

.theme-hint {
  top: 6px;
}

}

@media screen and (max-width: 700px) {
.theme-hint {
  top: 140px;
  right: 40px;
}

.theme-hint-main {
  top: 0;
  height: 100vh;
}

.theme-para {
  display: block;
}

.theme-setting-view {
  display: flex;
  position: absolute;
  z-index: 470;
  color: black;
  background-color: white;
  padding: 5px 15px;
  width: 180px;
  top: 90px;
  right: 15px;
  gap: 8px;
  align-items: center;
}
}

@media screen and (max-width: 769px) {
.user-setting-hint{
  top: 60px;
  left: 25px;
}
.user-setting-hint .hintpopup{
  width: 325px !important;
}
.user-setting-hint .hintpopup.arrow-right:before {
  top: calc(50% + 99px) !important;
  right: 263px !important;
}
.user-setting-hint .hintpopup:before{
  transform: rotate(136deg) !important;
}
}

.users-setting-view {
display: none;
}

.orgData-setting-view{
display: none;
}

@media screen and (max-width: 800px) {
.users-setting-view{
  display: flex;
  gap: 8px;
  width: 96%;
  height: 40px;
  background: white;
  position: absolute;
  top: 305px;
  padding: 0 10px;
  left: 13px;
  align-items: center;
  border-radius: 6px;
}
}

@media screen and (max-width: 700px) {
.orgData-setting-view {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 516;
  color: black;
  background-color: white;
  padding: 4px 0px;
  border-radius: 4px;
  width: 129px;
  top: 326px;
  right: 265px;
}
}

@media screen and (max-width: 500px) {
.orgData-hint .hintpopup{
  bottom: 102px;
  right: 180px;
}

.orgData-setting-view {
  right: unset;
  left: 257px;
}
.orgData-user-hint-view .hintpopup{
  bottom: 66px;
}
}

@media screen and (max-width: 419px) and (min-width: 377px) {
.orgData-user-hint-view .hintpopup {
  bottom: 105px;
}
}

@media screen and (max-width: 376px) {
.orgData-setting-view {
  left: 225px;
}
.orgData-user-hint-view .hintpopup{
  bottom: 118px;
}
}

@media screen and (max-width: 321px) {
.orgData-hint {
  top: 173px;
  left: 64%;
}

.orgData-setting-view {
  left: 175px;
}
.orgData-user-hint-view .hintpopup{
  bottom: 100px;
}

.user-setting-hint .hintpopup{
  right: 27px;
}

}