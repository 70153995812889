.organisation-deletion-container{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.organisation-deletion-authContainer{
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.organisation-deletion-auth{
    display: flex;
    gap: 16px;
}

.organisation-deletion-btn{
    display: flex;
    justify-content: flex-end;
}

.org-deletion-auth{
    width: 32%;
}

.org-deletion-eyeIcon{
    bottom: 32px;
}

#org-delete-undisable{
    background: #DC2626;
    color: white;
}

#org-delete-undisable span{
    color: white !important;
}

.org-del-err-mes-btn .ui-err-mes-container{
    justify-content: center;
    align-items: center;
}

/* Media Query for MobileView */

@media screen and (max-width: 1000px) {
    .organisation-deletion-auth{
        flex-direction: column;
    }
    .org-deletion-auth{
        width: unset;
    }
}