.app-support-container{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.app-support-cardContainer{
    display: flex;
    gap: 12px;
}

.app-support-card{
    width: 290px;
    height: 81px;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.app-support-cardContent{
    display: flex;
    gap: 20px;
    align-items: center;
}

.app-support-cardTitle{
    font-family: 'Inter';
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.app-support-cardTitle1{
    margin: 0;
    font-size: 13px;
}

.app-support-cardTitle2-phone{
    margin: 0;
    color: #1E293B;
    font-size: 15px;
}

.app-support-cardTitle2-email{
    margin: 0;
    color: #1E293B;
    font-size: 15px;
}

.app-support-cardTitle2-whatsapp{
    margin: 0;
    color: #1E293B;
    font-size: 15px;
}

.app-support-phoneIcon{
    font-size: 20px;
    color: #2563EB;
}

.app-support-emailIcon{
    font-size: 20px;
    color: #7C3AED;
}

.app-support-copyIcon{
    color: #64748B;
    cursor: pointer;
}

.support-phone{
    color: #2563EB;
}

.support-email{
    color: #7C3AED;
}

.support-whatsapp{
    color: #059669;
}

@media screen and (max-width: 1175px) {
    .app-support-card{
        padding: 11px !important;
    }
}

@media screen and (max-width: 1000px) {
    .app-support-card{
        padding: 20px !important;
        width: unset;
    }
    .app-support-cardContainer{
        flex-direction: column;
    }
}