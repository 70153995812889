.header-selection-tree {
    padding: 5px;
    display: flex;
    justify-content: space-between;

}

.text-activity {
    font-weight: 400;
    font-size: 12px;
    color: #64748B;
}

.text-select-tree {
    font-weight: 500;
    font-size: 12px;
    color: #2563EB;
}

.horizontal-line {
    border: none;
    border-top: 1px solid #cdd4e0;
    margin: 10px 0;
}

.add-role {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #3B82F6;
    cursor: pointer;
}

.container-icon-role {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.empty-role-text {
    font-weight: 500;
    font-size: 15px;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.container-roles {
    justify-content: center;
    display: grid;
    padding: 24px;
}

.title-400 {
    font-weight: 400;
    font-size: 12px;
    color: #64748B;
}
.badge{
    padding: 3px 8px 3px 8px;
    border-radius: 4px;
    width: fit-content;
    display: flex;
    justify-content: center;
    text-align: center;

}

.color-proprietaire {
    background-color: rgb(236, 253, 245);
    border: 1px solid rgb(52, 211, 153);
    color: rgb(51, 65, 85);
}

.color-admin {
    background-color: #EFF6FF;
    border: 1px solid #60A5FA;
    color: #1D4ED8;
}

.color-citizen-developer {
    background-color: #F5F3FF;
    border: 1px solid #A78BFA;
    color: #6D28D9;
}

.color-utilisateur {
    background-color: #FEFCE8;
    border: 1px solid #FACC15;
    color: #A16207;
}
.badge-color-na{
    padding: 3px 8px 3px 8px;
    border-radius: 4px;
    width: 120px;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color:#ffffff;
    border: 1px solid #F5F5F5;
    color: #000000;
}

.drop-souche {
    display: flex;
}

.drop-souche .list-options {
    position: initial;
    margin-top: 10px;
    z-index: 0;

}

.role-organisation-dropdown {
    font-family: 'Inter';
}

.role-organisation-dropdown .list_list-container__BYgx6 {
    position: initial;
}

.table-users-container{
    padding: 35px;
}
.table-users-container .list-options{
    top: 0px !important;
}

.no-results {
    display: flex;
    justify-content: center;
    padding: 15px;
    font-size: 13px;
    color: #94A3B8;
}

.add-name-modal-text {
    color: #1E293B;
    font-weight: 500;
    font-size: 13px;
    font-family: 'Inter';

}

.about-tabs-informations {
    padding: 24px 16px 24px 16px;
    line-height: 45px;
}

.roles-empty-container {
    /* width: Fill (940px);
  height: Hug (275px); */
  padding:24px;
  gap: 32px;
  margin-right: auto;
  margin-left: auto;
  font-family: 'Inter';
    
  }
.roles-empty-container .column{
    color:#334155
}

.roles-empty-container .column {
    color: #334155
}

.inputs-delete-modal {
    display: flex;
    justify-content: center;
    gap: 10px;
    color: #1E293B;
    font-size: 25px;
    font-weight: 400;
    align-items: center;

}

.inputs-delete-modal input {
    width: 52px;
    height: 52px;
    border-radius: 6px;
    border-color: #e2e8f03b;
    text-align: center;


}

.separator {
    margin: 0 5px;
    font-size: 20px;
    color: #94A3B8;
}

.text-title-delete {
    display: flex;
    justify-content: center;
}

.errpr-icon {
    color: red;
}

.tabs-roles-edit {

    margin-bottom: 10px;
}

.tabs-roles-edit .tabs-kls {
    background-color: #fff;
}

.tabs-roles-edit .tabs-kls .tab.selected:hover {
    font-weight: 500;
}

.tabs-roles-edit .tabs-kls .tab:hover {
    font-weight: 400;
}

.tabs-roles-edit .tabs-kls .tab-pull-right {
    flex: none !important;
}

.flex-space {
    display: flex;
    /* justify-content: space-between; */
  }
  .title-propos{
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    color:#1E293B;
    width: 220px;
  }
  .descp-propos{
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
    color: #64748B;
}

.error-div {
    display: flex;
    background-color: #FEF2F2;
    border-radius: 4px;
    border-color: #FCA5A5;
    border: 1px solid #FCA5A5;
    justify-content: center;
    padding: 8px 16px 8px 16px;
    gap: 8px;
    margin-top: 20px;

}

.error-msg {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #334155;

}



.parametre-app{
    padding:20px 30px;
}


.parametre-app {
    padding: 20px 30px;
}

.parametre-app-details {
    margin: 20px 0;
    display: flex;
}

.parametre-app-items {
    border: 1px solid #D1D5DB;
    border-radius: 3px;
    width: 75%;
    margin: 5px 10px;
    padding-bottom: 20px;
}

.parametre-app-details .tab-container {
    width: 20%;

}

.menu-icon {
    font-size: 100px;
    color: #65A30D;
}

.table-more-vert {
    color: #64748b;
    display: flex;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
}

.menu-creation-header {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 10px;
}

.menu-content {
    display: grid;
    justify-content: center;
    text-align: center;
    padding: 20px
}

.menu-tables {
    padding: 20px;
    display: grid;
    gap: 15px
}

.ress-field .text-sm.font-400 {
    margin-right: 23px;
}

.dlt-menu-res {
    position: relative;
    right: 45px;
    top: 33px;
    cursor: pointer;
    font-size: 12px;
}

.more-options {
    width: 110px;
    position: absolute;
    background: #FFF;
    display: flex;
    flex-direction: column;
    gap: 7px;
    border: 1px solid #e2e8f0;
    padding: 5px;
    box-shadow: 0 4px 6px -1px #0000001a;
    z-index: 100;
    right: 11px;
    margin: -7px 0;

}

.options-details {
    display: flex;
    cursor: pointer;
    padding: 5px;
}

.options-details:last-child {
    color: #DC2626;
}

.options-details:hover {
    background-color: #F1F5F9;
}

.actif-menu {
    display: flex;
    align-items: center;
    gap: 5px;
}

.save-menu {
    display: flex;
    justify-content: flex-end;
    margin-right: 3rem;
}

.xml-generic-attribute-panel .screeen-size-selector .screen-icon {
    width: 100%;
}

.xml-generic-attribute-panel .screeen-size-selector .piltabs-container {
    width: 100%;
    margin-right: 0px;
}

.menu-att-details {
    padding: 10px;
    height: 100%;
}

.advanced-drop-menu {
    display: flex;
    justify-content: space-between;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    align-items: center;
    height: 34px;
    cursor: pointer;
    width: 100%;
    margin-top: 2px;
    left: 15rem;
}

.advanced-drop-menu-list {
    display: flex;
    gap: 5px;
    padding: 7px 8px;
    align-items: center
}

.piltab-appComponent .screeen-size-selector .piltabs-container {
    margin-right: 0px;
}

.application-title-Proprietaire {
    display: flex;
    flex-direction: row;

    gap: 10px;
}

.no-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.new-menu {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.user-grid-view-icon,
.user-list-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.user-moreoptions-list,
.user-moreoptions-list-active {
    right: 120px;
    position: relative;
}

.user-moreoptions-list-request {
    right: 137px;
    position: relative;
}

.app-groups-mobile-viewVerification {
    display: none;
}

.app-strains-mobile-viewVerification {
    display: none;
}

.mobile-strains-verification-view {
    overflow: hidden;
}

@media screen and (max-width:600px) {
    .strains-headerSettings .container-head {
        height: 90px !important;
    }

    .strains-headerSettings .container-button button {
        width: max-content !important;
    }

    .strains-headerSettings .container-subtitle {
        width: calc(100% + 10px);
    }

    .strains-headerSettings .container-subtitle {
        width: calc(100% + 10px);
    }

    .group-header-settings .container-head {
        height: 90px !important;
    }

    .group-header-settings .container-button button {
        width: max-content !important;
    }

    .app-groups-lap-deteleModal {
        display: none;
    }

    .app-groups-mobile-viewVerification {
        display: block;
    }

    .app-strains-tab-modalDetele {
        display: none;
    }

    .app-strains-mobile-viewVerification {
        display: block;
    }
}

@media screen and (max-width:332px) {
    .group-header-settings .container-head {
        height: 110px !important;
    }
}

.user-search-div {
    display: flex;
    gap: 20px;
    flex-direction: row;
}


@media screen and (max-width: 1250px) {
    .users-table-data-scroll {
        overflow: scroll;
        scrollbar-width: none;
    }

    .user-moreoptions-list,
    .user-moreoptions-list-active {
        right: 0;
    }
}



.user-card-main {
    width: 203px;
    height: 244px;
    border-radius: 25.58px;
    padding: 11px 29px;
    box-shadow: 0px 2.05px 38.36px 0px #B7B7B73D;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-card-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
}

.user-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 19px;
}

.user-card-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.user-card-details {
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: center;
    justify-content: center;
}

.user-card-email {
    height: 22px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    min-width: 140px;
    padding: 5px 20px;
    color: white;
}

@media screen and (max-width:800px) {
    .user-search-div {
        flex-direction: column !important;
    }

    .user-search-bar-primary {
        width: 100% !important;
    }
}


.user-moreoptions-container {
    position: absolute;
    top: -10px;
    right: 0;
}

.user-card-profile {
    width: 98px;
    height: 98px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2.28px solid transparent;
}

.user-card-profile-outline {
    width: 91px;
    height: 91px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.user-card-profile-initial {
    width: 91px;
    height: 91px;
    border-radius: 50%;
    font-size: 31.2px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-card-profile-image {
    width: 91px;
    height: 91px;
    border-radius: 50%;
    border: 1px solid rgba(30, 41, 59, 0.1);
    object-fit: cover;
}

.user-grid-profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid rgba(30, 41, 59, 0.1);
    object-fit: cover;
}

.users-list-mobile,
.users-table-mobile {
    display: none;
}

.user-list-profile-mobile {
    box-shadow: 0px 4px 6px -4px #0000001A, 0px 10px 15px -3px #0000001A;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-list-profile-mobile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.user-list-details-mobile {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.mobile-groups-verification-view {
    overflow: hidden;
}

/* .group-table-data-scroll{
    overflow: scroll;
}

.group-table-data-scroll::-webkit-scrollbar{
    display: none;
}

.strains-table-data-scroll{
    overflow: scroll;
}

.strains-table-data-scroll::-webkit-scrollbar{
    display: none;
} */


@media screen and (max-width: 1025px) {
    .group-table-data-scroll {
        overflow: scroll;
    }

    .group-table-data-scroll::-webkit-scrollbar {
        display: none;
    }

    .strains-table-data-scroll {
        overflow: scroll;
    }

    .strains-table-data-scroll::-webkit-scrollbar {
        display: none;
    }
}

.user-search-div>span {
    width: 70%;
}

.users-search-view {
    display: flex;
    gap: 20px;
}

.users-change-view {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

@media screen and (max-width: 450px) {
    .users-list-mobile {
        display: flex;
    }

    .users-table-mobile {
        display: block;
        border-bottom: 1px solid #DCDCDC;
        padding: 20px 0px 20px 17px;
    }

    .users-table-data-scroll {
        display: none;
    }

    .users-search-view {
        display: none;
    }

    .user-search-div>span {
        width: 100%;
    }
}

@media screen and (max-width: 800px) and (min-width: 557px) {
    .group-option-dropdown {
        position: absolute;
        left: -74px;
    }

}

@media screen and (max-width: 800px) and (min-width: 601px) {
    .strain-option-dropdown {
        position: absolute !important;
        left: -84px !important;
    }
}

.header-selection-tree {
    padding: 5px;
    display: flex;
    justify-content: space-between;

}

.text-activity {
    font-weight: 400;
    font-size: 12px;
    color: #64748B;
}

.text-select-tree {
    font-weight: 500;
    font-size: 12px;
    color: #2563EB;
}

.horizontal-line {
    border: none;
    border-top: 1px solid #cdd4e0;
    margin: 10px 0;
}

.add-role {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #3B82F6;
    cursor: pointer;
}

.container-icon-role {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.empty-role-text {
    font-weight: 500;
    font-size: 15px;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.container-roles {
    justify-content: center;
    display: grid;
    padding: 24px;
}

.title-400 {
    font-weight: 400;
    font-size: 12px;
    color: #64748B;
}

.badge-organisation {
    border-radius: 4px;
    width: fit-content;
    text-align: center;
}

.badge {
    padding: 3px 8px 3px 8px;
    border-radius: 4px;
    width: fit-content;
    display: flex;
    justify-content: center;
    text-align: center;

}

.color-proprietaire {
    background-color: rgb(236, 253, 245);
    border: 1px solid rgb(52, 211, 153);
    color: rgb(51, 65, 85);
}

.color-admin {
    background-color: #EFF6FF;
    border: 1px solid #60A5FA;
    color: #1D4ED8;
}

.color-citizen-developer {
    background-color: #F5F3FF;
    border: 1px solid #A78BFA;
    color: #6D28D9;
}

.color-utilisateur {
    background-color: #FEFCE8;
    border: 1px solid #FACC15;
    color: #A16207;
}

.drop-souche {
    display: flex;
}

.drop-souche .list-options {
    position: initial;
    margin-top: 10px;
    z-index: 0;

}

.role-organisation-dropdown {
    font-family: 'Inter';
}

.role-organisation-dropdown .list_list-container__BYgx6 {
    position: initial;
}

.table-users-container .list-options {
    top: 0px !important;
}

.no-results {
    display: flex;
    justify-content: center;
    padding: 15px;
    font-size: 13px;
    color: #94A3B8;
}

.add-name-modal-text {
    color: #1E293B;
    font-weight: 500;
    font-size: 13px;
    font-family: 'Inter';

}

.about-tabs-informations {
    padding: 24px 16px 24px 16px;
    line-height: 45px;
}

.roles-empty-container {
    /* width: Fill (940px);
  height: Hug (275px); */
    /* padding:24px; */
    gap: 32px;
    margin-right: auto;
    margin-left: auto;
    font-family: 'Inter';

}

.roles-empty-container .column {
    color: #334155
}

.inputs-delete-modal {
    display: flex;
    justify-content: center;
    gap: 10px;
    color: #1E293B;
    font-size: 25px;
    font-weight: 400;
    align-items: center;

}

.inputs-delete-modal input {
    width: 52px;
    height: 52px;
    border-radius: 6px;
    border-color: #e2e8f03b;
    text-align: center;


}

.separator {
    margin: 0 5px;
    font-size: 20px;
    color: #94A3B8;
}

.text-title-delete {
    display: flex;
    justify-content: center;
}

.errpr-icon {
    color: red;
}

.tabs-roles-edit {

    margin-bottom: 10px;
}

.tabs-roles-edit .tabs-kls {
    background-color: #fff;
}

.tabs-roles-edit .tabs-kls .tab.selected:hover {
    font-weight: 500;
}

.tabs-roles-edit .tabs-kls .tab:hover {
    font-weight: 400;
}

.tabs-roles-edit .tabs-kls .tab-pull-right {
    flex: none !important;
}

.flex-space {
    display: flex;
    justify-content: space-between;
}

.title-propos {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    color: #1E293B
}

.descp-propos {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
    color: #64748B;
}

.error-div {
    display: flex;
    background-color: #FEF2F2;
    border-radius: 4px;
    border-color: #FCA5A5;
    border: 1px solid #FCA5A5;
    justify-content: center;
    padding: 8px 16px 8px 16px;
    gap: 8px;
    margin-top: 20px;

}

.error-msg {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #334155;

}


.parametre-app {
    padding: 20px 30px;
}

.parametre-app-details {
    margin: 20px 0;
    display: flex;
}

.parametre-app-items {
    border: 1px solid #D1D5DB;
    border-radius: 3px;
    width: 75%;
    margin: 5px 10px;
    padding-bottom: 20px;
}

.parametre-app-details .tab-container {
    width: 20%;

}

.menu-icon {
    font-size: 100px;
    color: #65A30D;
}

.table-more-vert {
    color: #64748b;
    display: flex;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
}

.menu-creation-header {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 10px;
}

.menu-content {
    display: grid;
    justify-content: center;
    text-align: center;
    padding: 20px
}

.menu-tables {
    padding: 20px;
    display: grid;
    gap: 15px
}

.more-options {
    width: 110px;
    position: absolute;
    background: #FFF;
    display: flex;
    flex-direction: column;
    gap: 7px;
    border: 1px solid #e2e8f0;
    padding: 5px;
    box-shadow: 0 4px 6px -1px #0000001a;
    z-index: 100;
    right: 11px;
    margin: -7px 0;

}

.options-details {
    display: flex;
    cursor: pointer;
    padding: 5px;
}

.options-details:last-child {
    color: #DC2626;
}

.options-details:hover {
    background-color: #F1F5F9;
}

.actif-menu {
    display: flex;
    align-items: center;
    gap: 5px;
}

.save-menu {
    display: flex;
    justify-content: flex-end;
    margin-right: 3rem;
}

.xml-generic-attribute-panel .screeen-size-selector .screen-icon {
    width: 100%;
}

.xml-generic-attribute-panel .screeen-size-selector .piltabs-container {
    width: 100%;
    margin-right: 0px;
}

.menu-att-details {
    padding: 10px;
    height: 100%;
}

.advanced-drop-menu {
    display: flex;
    justify-content: space-between;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    align-items: center;
    height: 34px;
    cursor: pointer;
    width: 100%;
    margin-top: 2px;
    left: 15rem;
}

.advanced-drop-menu-list {
    display: flex;
    gap: 5px;
    padding: 7px 8px;
    align-items: center
}

.piltab-appComponent .screeen-size-selector .piltabs-container {
    margin-right: 0px;
}

.application-title-Proprietaire {
    display: flex;
    flex-direction: row;

    gap: 10px;
}

.no-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.new-menu {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.user-grid-view-icon,
.user-list-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.user-moreoptions-list,
.user-moreoptions-list-active {
    right: 120px;
    position: relative;
}

.user-moreoptions-list-request {
    right: 137px;
    position: relative;
}

.app-groups-mobile-viewVerification {
    display: none;
}

.app-strains-mobile-viewVerification {
    display: none;
}

.mobile-strains-verification-view {
    overflow: hidden;
}

@media screen and (max-width:600px) {
    .strains-headerSettings .container-head {
        height: 90px !important;
    }

    .strains-headerSettings .container-button button {
        width: max-content !important;
    }

    .strains-headerSettings .container-subtitle {
        width: calc(100% + 10px);
    }

    .strains-headerSettings .container-subtitle {
        width: calc(100% + 10px);
    }

    .group-header-settings .container-head {
        height: 90px !important;
    }

    .group-header-settings .container-button button {
        width: max-content !important;
    }

    .app-groups-lap-deteleModal {
        display: none;
    }

    .app-groups-mobile-viewVerification {
        display: block;
    }

    .app-strains-tab-modalDetele {
        display: none;
    }

    .app-strains-mobile-viewVerification {
        display: block;
    }
}

@media screen and (max-width:332px) {
    .group-header-settings .container-head {
        height: 110px !important;
    }
}

.user-search-div {
    display: flex;
    gap: 20px;
    flex-direction: row;
}


@media screen and (max-width: 1250px) {
    .users-table-data-scroll {
        overflow: scroll;
        scrollbar-width: none;
    }

    .user-moreoptions-list,
    .user-moreoptions-list-active {
        right: 0;
    }
}



.user-card-main {
    width: 203px;
    height: 244px;
    border-radius: 25.58px;
    padding: 11px 29px;
    box-shadow: 0px 2.05px 38.36px 0px #B7B7B73D;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-card-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
}

.user-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 19px;
}

.user-card-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.user-card-details {
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: center;
    justify-content: center;
}

.user-card-email {
    height: 22px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    min-width: 140px;
    padding: 5px 20px;
    color: white;
}

@media screen and (max-width:800px) {
    .user-search-div {
        flex-direction: column !important;
    }

    .user-search-bar-primary {
        width: 100% !important;
    }
}


.user-moreoptions-container {
    position: absolute;
    top: -10px;
    right: 0;
}

.user-card-profile {
    width: 98px;
    height: 98px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2.28px solid transparent;
}

.user-card-profile-outline {
    width: 91px;
    height: 91px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.user-card-profile-initial {
    width: 91px;
    height: 91px;
    border-radius: 50%;
    font-size: 31.2px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-card-profile-image {
    width: 91px;
    height: 91px;
    border-radius: 50%;
    border: 1px solid rgba(30, 41, 59, 0.1);
    object-fit: cover;
}

.user-grid-profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid rgba(30, 41, 59, 0.1);
    object-fit: cover;
}

.users-list-mobile,
.users-table-mobile {
    display: none;
}

.user-list-profile-mobile {
    box-shadow: 0px 4px 6px -4px #0000001A, 0px 10px 15px -3px #0000001A;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-list-profile-mobile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.user-list-details-mobile {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.mobile-groups-verification-view {
    overflow: hidden;
}

/* .group-table-data-scroll{
    overflow: scroll;
}

.group-table-data-scroll::-webkit-scrollbar{
    display: none;
}

.strains-table-data-scroll{
    overflow: scroll;
}

.strains-table-data-scroll::-webkit-scrollbar{
    display: none;
} */


@media screen and (max-width: 1025px) {
    .group-table-data-scroll {
        overflow: scroll;
    }

    .group-table-data-scroll::-webkit-scrollbar {
        display: none;
    }

    .strains-table-data-scroll {
        overflow: scroll;
    }

    .strains-table-data-scroll::-webkit-scrollbar {
        display: none;
    }
}

.user-search-div>span {
    width: 70%;
}

.users-search-view {
    display: flex;
    gap: 20px;
}

.users-change-view {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

@media screen and (max-width: 450px) {
    .users-list-mobile {
        display: flex;
    }

    .users-table-mobile {
        display: block;
        border-bottom: 1px solid #DCDCDC;
        padding: 20px 0px 20px 17px;
    }

    .users-table-data-scroll {
        display: none;
    }

    .users-search-view {
        display: none;
    }

    .user-search-div>span {
        width: 100%;
    }
}

@media screen and (max-width: 800px) and (min-width: 557px) {
    .group-option-dropdown {
        position: absolute;
        left: -74px;
    }

}

.chemin-input .font-400 {
    background-color: #F1F5F9;
}

.chemin-input [class*="input_readOnly"] {
    background-color: #F1F5F9;

}