.setting-profile {
    display: flex;
    gap: 8px;
    align-items: center;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    padding: 8px;
    font-size: 13px;
}
.organisation-header-dropdown .list-options{
    width: 100%;
}
.organisation-header-dropdown {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 50px;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    width: 100%;
    font-family: 'Inter';
    z-index: 10;
    background-color: white;
    padding: 5px;
    box-shadow: 0px 8px 10px -6px #0000001A, 0px 20px 25px -5px #0000001A;
}
.user-drop{
    display: flex;
    flex-direction: row;
    align-content: space-between;
    flex-wrap: wrap;
    margin: 0px;
    align-items: center;
}

.setting-label{
    font-size: 13px;
    margin: 0;
    font-weight: 400;
    font-family: 'Inter';
}

.setting-user{
    margin: 0px;
    font-size: 13px;
    margin-top: 3px;
    font-family: 'Inter';
}

.more-icon-set{
    margin: 4px 0px 0px 8px;
}
[data-settings-inital]:before {
    background: var(--blue-600);
    color: white;
    opacity: 1;
    content: attr(data-settings-inital);
    display: inline-block;
    font-weight: bold;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 0.5em;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 8px;
}
.settings-avatar {
    display: flex;
    align-items: center;
}
img.set-pro-img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
}

.current-page {
    width: 23px;
    height: 20px;
    border: 1px solid #3B82F6;
    color: white;
    background-color: #60A5FA;
    border-radius: 4px;
    display: flex;
    justify-content: center;
}