.verification-settings-content-container{
    padding: 32px 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.verification-setting-content-status{
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: flex-end;
}

.verification-setting-content-status h1{
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    color: var(--slate-800, #1E293B);
    margin: 0;
    padding: 0;
}
.verification-setting-content-status p{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    color: var(--slate-800, #1E293B);
    margin: 0;
    padding: 0;
}

.verification-setting-content-status-subDiv{
    display: flex;
    align-items: center;
    gap: 4px;
}

.verification-setting-content-status-color{
    width: 6.5px;
    height: 6.5px;
    border-radius: 100px;
    background-color: #EF4444;
}

.verification-settings-upload-container{
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.verification-settings-upload-header h1{
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    color: #1D4ED8;
}

.verification-settings-upload-box{
    padding: 24px 16px;
    border-radius: 8px;
    border: 1px dashed var(--blue-500, #3B82F6);
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.upload-icon-verify-settings{
    font-size: 20px;
    color: #2563EB;
}

.file-upload-verification-settings{
    display: none; 
}

.verification-settings-upload-description{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
}

.verification-settings-upload-description h1{
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    color: var(--slate-800, #1E293B);
    margin: 0;
    padding: 0;
}
.verification-settings-upload-description h2{
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 400;
    color: var(--slate-700, #334155);
    margin: 0;
    padding: 0;
}

.verification-settings-upload-submit{
    display: flex;
    justify-content: flex-end;
}

.icon-doc-verify{
    font-size: 16px;
    color: #64748B;
}

.icon-delete-verify{
    color: #DC2626;
    font-size: 14px;
    cursor: pointer;
}
.icon-eye-verify{
    color: #2563EB;
    font-size: 14px;
    cursor: pointer;
}
.verification-setting-uploaded-data-ul{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

.verification-setting-uploaded-data-li{
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    border: 1px solid var(--slate-200, #E2E8F0);
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px #0000000D;
    margin-bottom: 8px;
}

.verification-setting-uploaded-data-li-detials,.verification-setting-uploaded-data-li-options{
    display: flex;
    gap: 7px;
    align-items: center;
}

.verification-setting-uploaded-data-name{
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    color: var(--slate-800, #1E293B);
    margin: 0;
    padding: 0;
}

.verification-setting-uploaded-data-size{
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    color: #64748B;
    margin: 0;
    padding: 0;
}

.error-message{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#FEF2F2 ;
    border: #FCA5A5 1px solid;
    box-shadow: 0px 1px 2px 0px #0000000D;
    gap: 6px;
    padding: 8px 12px;
    border-radius: 4px;
}
.error-logo-org{
    color: #DC2626;
}

.verify-error-message{
    font-family: "Inter";
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    color: #1E293B;
}

.verification-setting-model-popup img {
    width: 400px;
    display: flex;
    margin: 2px auto;
}

@media screen and (max-width: 780px) { 
    .verification-settings-container .container-head{
        height: 94px !important
    }
}
@media screen and (max-width: 340px) { 
    .verification-settings-container .container-head{
        height: 110px !important
    }
}